<template>
  <div>
    <v-chip
      small
      dense
      label
      :color="color"
      v-for="tag in tags"
      v-bind:key="tag"
      class="ma-1"
    >
      {{ tag }}
    </v-chip>
    <!--
    <div
      small
      dense
      label
      :color="color"
      v-for="tag in tags"
      v-bind:key="tag"
      class="tag ma-1"
      :style="{ 'background-color': color }"
    >
      {{ tag }}
    </div> -->
  </div>
</template>

<script>
export default {
  name: "TagsPlate",
  props: {
    tags: Array,
    color: {
      type: String,
      default: "#ADD8E6",
    },
    small: Boolean,
  },
};
</script>

<!--
<style>
.tag {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 0px 12px 0px 12px;
  font-size: 12px;
  border-radius: 4px;
}
@media print {
}
</style>
-->
