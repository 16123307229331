<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="invites"
      sort-by="calories"
      class="elevation-1"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn text color="primary" :to="{ name: 'Projects' }">{{
            $t("learningDesign.myLearningDesigns")
          }}</v-btn>
          <v-btn text color="primary" :to="{ name: 'DashboardCollab' }">{{
            $t("invites.myCollabs")
          }}</v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            text
            outlined
            @click="createProject('blank')"
            class="text-caption"
          >
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t("learningDesign.newLearningDesign") }}
          </v-btn>
        </v-toolbar>
        <v-col cols="6" v-if="tags.length > 0">
          <multiselect
            v-model="searchTags"
            tag-placeholder="Add this as new tag"
            placeholder="Select tag"
            :options="tags"
            :multiple="true"
            :showNoOptions="false"
          ></multiselect>
        </v-col>
      </template>

      <template v-for="slot in headers" v-slot:[slot.slotName]>
        <div v-bind:key="slot.slotName" class="table-header pl-3">
          {{ $t(slot.text) }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-container>
          <div style="white-space: nowrap">
            <v-tooltip
              bottom
              max-width="272"
              color="green"
              content-class="custom-tooltip"
              v-if="!item.is_mine && item.status === 'pending'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  color="green"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="accept(item)"
                  ><v-icon small>mdi-check</v-icon>
                </v-btn>
              </template>
              {{ $t("invites.accept") }}
            </v-tooltip>

            <v-tooltip
              bottom
              max-width="272"
              color="red"
              content-class="custom-tooltip"
              v-if="!item.is_mine && item.status === 'pending'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="decline(item)"
                  ><v-icon small>mdi-close</v-icon>
                </v-btn>
              </template>
              {{ $t("invites.reject") }}
            </v-tooltip>

            <v-tooltip
              bottom
              max-width="272"
              color="red"
              content-class="custom-tooltip"
              v-if="!item.is_mine && item.status === 'accepted'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="remove(item)"
                  ><v-icon small>mdi-logout</v-icon>
                </v-btn>
              </template>
              {{ $t("invites.leave") }}
            </v-tooltip>

            <v-tooltip
              bottom
              max-width="272"
              color="red"
              content-class="custom-tooltip"
              v-if="item.is_mine && item.resultFragmentAccess"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="removeCoworker(item)"
                  ><v-icon small>mdi-account-remove</v-icon>
                </v-btn>
              </template>
              {{ $t("invites.revokeAccess") }}
            </v-tooltip>

            <v-tooltip
              bottom
              max-width="272"
              color="red"
              content-class="custom-tooltip"
              v-if="item.is_mine && !item.resultFragmentAccess"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="deleteDocument(item)"
                  ><v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t("invites.deleteDocument") }}
            </v-tooltip>
          </div>
        </v-container>
      </template>

      <template v-slot:item.doc_name="{ item }">
        <v-container>
          <div v-if="item.status !== 'accepted' && !item.is_mine">
            {{ item.doc_name }}
          </div>
          <div v-else>
            <router-link
              :to="{ name: 'Project', params: { id: item.doc_id } }"
              >{{ item.doc_name }}</router-link
            >
          </div>
          <br />
          <v-spacer />
          <TagsPlate color="#ADD8E6" :tags="item.tags" />
        </v-container>
      </template>

      <template v-slot:item.status="{ item }">
        <v-container>
          <v-chip
            v-if="item.status"
            class="ma-2"
            label
            :color="getStatusColor(item.status)"
            dense
            small
            light
            :text-color="getStatusTextColor(item.status)"
          >
            {{ $t(`status.${item.status}`) }}
          </v-chip>
        </v-container>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <v-container>
          {{ $date(item.createdAt) }}
        </v-container>
      </template>

      <template v-slot:item.lastModified="{ item }">
        <v-container>
          {{ item.lastModified ? $date(item.lastModified) : "" }}
        </v-container>
      </template>

      <template v-slot:item.createdBy="{ item }">
        <v-container>
          <div v-if="item.is_mine"></div>
          <div v-else>
            {{ getDocumentOwner(item) }}
          </div>
        </v-container>
      </template>

      <template v-slot:item.coworker="{ item }">
        <v-container>
          <div v-if="!item.is_mine">
            <b>{{ getDocumentOwner(item) }}</b>
            <br />
            {{ $print_role("owner").toLowerCase() }}
          </div>
          <div v-else>
            <b>{{ getDocumentCoworker(item) }}</b>
            <br />
            {{ getDocumentCoworkerRoles(item) }}
          </div>
        </v-container>
      </template>

      <template v-slot:item.roles="{ item }">
        <v-container>
          <div v-if="!item.is_mine">
            {{ getDocumentCoworkerRoles(item) }}
          </div>
          <div v-else>{{ $print_role("owner").toLowerCase() }}</div>
        </v-container>
      </template>
      <template v-slot:item.tags="{ item }">
        <v-chip
          small
          dense
          label
          color="green"
          v-for="tag in item.tags"
          v-bind:key="tag"
          class="ma-1 rounded-0"
        >
          {{ tag }}
        </v-chip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */

import { mapGetters } from "vuex";
import TagsPlate from "./TagsPlate.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "DashboardCollab",
  props: {
    title: String,
    filter: {
      type: Array,
      default() {
        return [];
      },
    },
    compact: {
      type: String,
      default: "collab",
    },
    "action-new-project": Boolean,
  },
  components: {
    TagsPlate,
    Multiselect,
  },
  data: () => ({
    headers: [],
    defaultHeaders: [
      /**
      {
        text: "invites.owner",
        align: "start",
        sortable: true,
        value: "createdBy",
        slotName: "header.createdBy",
      }, **/
      {
        text: "invites.coworkers",
        align: "start",
        sortable: true,
        value: "coworker",
        slotName: "header.coworker",
        hideCompact: ["owner"],
      },
      {
        text: "invites.document",
        value: "doc_name",
        slotName: "header.doc_name",
      },
      /**
      {
        text: "invites.tags",
        value: "tags",
        slotName: "header.tags",
      }, **/
      {
        text: "invites.started",
        value: "createdAt",
        slotName: "header.createdAt",
        hideCompact: ["owner"],
      },
      {
        text: "invites.created",
        value: "createdAt",
        slotName: "header.createdAt",
        hideCompact: ["collab"],
      },
      {
        text: "invites.modified",
        value: "lastModified",
        slotName: "header.lastModified",
        hideCompact: ["collab"],
      },
      {
        text: "status.status",
        value: "status",
        slotName: "header.status",
        hideCompact: ["owner"],
      },
      {
        text: "invites.myRoles",
        value: "roles",
        slotName: "header.roles",
        hideCompact: ["owner"],
      },
      {
        text: "invites.actions",
        value: "actions",
        slotName: "header.actions",
        sortable: false,
      },
    ],
    invites: [],
    invitesPrep: [],
    tags: [],
    searchTags: "",
  }),

  computed: {
    ...mapGetters(["user"]),
  },

  watch: {
    searchTags() {
      this.applyPrep();
    },
  },

  beforeMount() {},

  async mounted() {
    this.headers = this.defaultHeaders.filter((h) => {
      if (this.compact && (h.hideCompact || "").indexOf(this.compact) !== -1)
        return false;
      return true;
    });
    this.getReceivedInvitations();
  },

  methods: {
    async applyPrep() {
      if (this.searchTags.length === 0) {
        this.invites = JSON.parse(JSON.stringify(this.invitesPrep));
      } else {
        this.invites = JSON.parse(JSON.stringify(this.invitesPrep)).filter(
          (e) => {
            return (
              //  e.tags.filter((e) => this.searchTags.indexOf(e) !== -1).length > 0
              this.searchTags
                .map((st) => e.tags.indexOf(st) !== -1)
                .filter((e) => e).length === this.searchTags.length
            );
          }
        );
      }
    },
    async createProject(val) {
      await this.$store.dispatch("createProject", {
        type: val,
      });
    },
    getStatusColor(status) {
      if (status === "pending") return "blue";
      if (status === "declined") return "black";
      if (status === "accepted") return "blue-grey";
      return "primary";
    },
    getStatusTextColor(status) {
      if (status === "pending") return "white";
      if (status === "declined") return "white";
      if (status === "accepted") return "white";
      return "red";
    },
    getDocumentOwner(invite) {
      const tmp = invite.createdBy;
      return tmp.name || tmp.email || "some one";
    },
    getDocumentCoworker(invite) {
      if (typeof invite.recipient === "string") return invite.recipient;
      return invite.recipient.name || invite.recipient.email;
    },
    getDocumentCoworkerRoles(invite) {
      const roles = new Set(invite.roles);
      if (roles.has("editor")) roles.delete("viewer");
      return [...roles]
        .map((role) => this.$t(`roles.${role}`))
        .join(", ")
        .toLowerCase();
    },

    async accept(invite) {
      await this.$api.patch(
        `/fragments/${invite.doc_id}/invitations/${invite.id}/accept`
      );
      this.getReceivedInvitations();
    },
    async decline(invite) {
      const res = await this.$api.patch(
        `/fragments/${invite.doc_id}/invitations/${invite.id}/decline`
      );
      this.getReceivedInvitations();
    },
    async remove(invite) {
      const access_res = await this.$api.get(
        `/fragments/${invite.doc_id}/access`
      );
      const access = access_res?.data?.result?.filter(
        (res) => res.userId === this.user.id
      )?.[0];

      if (access && access.id) {
        const remove_res = await this.$api.delete(
          `/users/@me/fragment-access/${access.id}`
        );
      }
      this.getReceivedInvitations();
    },
    async removeCoworker(invite) {
      if (invite.status === "accepted") {
        const access = invite?.resultFragmentAccess;

        if (access && access.id) {
          const remove_res = await this.$api.delete(
            `/fragments/${access.rootFragmentId}/access/${access.id}`
          );
        }
      } else {
        const remove_res = await this.$api.delete(
          `/fragments/${invite.rootFragmentId}/invitations/${invite.id}`
        );
      }
      this.getReceivedInvitations();
    },
    async deleteDocument(item) {
      this.$store.dispatch("deleteProject", {
        id: item.rootFragmentId,
        callback: () => {
          this.getReceivedInvitations();
        },
      });
    },
    async getReceivedInvitations() {
      // https://api.dev.iluks.no/v2/users/@me/sent-and-received-invitations
      try {
        const projectAPI =
          "fragments/@me/created/with-latest-changes?page_number=1&page_size=1000";
        const myFrgamentsRes =
          this.filter.indexOf("owner") !== -1
            ? await this.$api.get(projectAPI)
            : [];
        const myFragments = (myFrgamentsRes?.data?.result?.data || []).map(
          (tmp) => {
            const lm = tmp.lastModified;
            tmp = tmp.root || {};
            return {
              id: Math.random() + "",
              createdAt: tmp.createdAt,
              lastModified: lm.lastModified || tmp.lastModified || null,
              rootFragmentId: tmp.id,
              recipientEmail: "",
              createdById: tmp.createdById,
              roles: [],
              resultFragmentAccess: false,
              createdBy: {
                id: tmp.createdById,
              },
              rootFragment: tmp,
              is_mine: true,
            };
          }
        );
        const { data } = await this.$api.get(
          // "/users/@me/fragment-access-invitations"
          "/users/@me/sent-and-received-invitations"
        );

        const usedIds = [
          ...(data?.result?.received || []).map((e) => {
            return e.rootFragmentId;
          }),
          ...(data?.result?.sent || []).map((e) => {
            return e.rootFragmentId;
          }),
        ];

        const blocks = [
          ...(data?.result?.received || [])
            .map((e) => {
              e.is_mine = false;
              return e;
            })
            .filter((e) => {
              if (this.filter.indexOf("owner") !== -1) return false;
              return true;
            }),
          ...(data?.result?.sent || [])
            .map((e) => {
              e.is_mine = true;
              return e;
            })
            .filter((e) => {
              if (this.filter.indexOf("owner") !== -1) return false;
              return true;
            }),
          ...myFragments.filter((f) => {
            if (this.filter.indexOf("owner") !== -1) return true;
            return usedIds.indexOf(f.rootFragmentId) === -1;
          }),
        ];
        this.tags = [];
        this.invitesPrep = blocks
          .filter((invite) => {
            if (this.filter.indexOf("owner") !== -1) {
              if (!invite.is_mine) {
                return false;
              }
            }
            if (
              this.$fg.SHOW_ALL_INVITES &&
              this.user.roles.indexOf("administrator") !== -1
            ) {
              return true;
            }
            if (invite.is_mine) return true;
            return ["pending", "accepted"].indexOf(invite.status) > -1;
          })
          .map((invite) => {
            const tmp = Object.assign({}, invite, {
              coworker: invite.recipient || invite.recipientEmail,
              status: invite.status,
              doc_id: invite?.rootFragment?.id,
              doc_name: invite?.rootFragment?.data?.name,
              tags: invite?.rootFragment?.data?.tags || [],
            });
            delete tmp.rootFragment;
            this.tags = [...this.tags, ...tmp.tags];
            return tmp;
          })
          .sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1;
            if (a.createdAt < b.createdAt) return 1;
            return 0;
          });
        this.tags = [...new Set(this.tags)].sort((a, b) => {
          const at = a.toLowerCase();
          const bt = b.toLowerCase();
          if (at > bt) return 1;
          if (at < bt) return -1;
          return 0;
        });
        this.applyPrep();
      } catch (error) {
        // console.log(error);
        this.invites = [];
        this.invitesPrep = [];
      }
    },
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
.table-header {
  display: inline;
}
.v-data-table-header__icon {
}

.v-data-table td {
  vertical-align: top;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tag {
  background-color: #add8e6;
  color: rgba(0, 0, 0, 0.87);
}
</style>
