var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invites,"sort-by":"calories","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"text":"","color":"primary","to":{ name: 'Projects' }}},[_vm._v(_vm._s(_vm.$t("learningDesign.myLearningDesigns")))]),_c('v-btn',{attrs:{"text":"","color":"primary","to":{ name: 'DashboardCollab' }}},[_vm._v(_vm._s(_vm.$t("invites.myCollabs")))]),_c('v-spacer'),_c('v-btn',{staticClass:"text-caption",attrs:{"small":"","color":"primary","text":"","outlined":""},on:{"click":function($event){return _vm.createProject('blank')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-thick ")]),_vm._v(" "+_vm._s(_vm.$t("learningDesign.newLearningDesign"))+" ")],1)],1),(_vm.tags.length > 0)?_c('v-col',{attrs:{"cols":"6"}},[_c('multiselect',{attrs:{"tag-placeholder":"Add this as new tag","placeholder":"Select tag","options":_vm.tags,"multiple":true,"showNoOptions":false},model:{value:(_vm.searchTags),callback:function ($$v) {_vm.searchTags=$$v},expression:"searchTags"}})],1):_vm._e()]},proxy:true},_vm._l((_vm.headers),function(slot){return {key:slot.slotName,fn:function(){return [_c('div',{key:slot.slotName,staticClass:"table-header pl-3"},[_vm._v(" "+_vm._s(_vm.$t(slot.text))+" ")])]},proxy:true}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-container',[_c('div',{staticStyle:{"white-space":"nowrap"}},[(!item.is_mine && item.status === 'pending')?_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"green","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green","dark":"","icon":"","small":""},on:{"click":function($event){return _vm.accept(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("invites.accept"))+" ")]):_vm._e(),(!item.is_mine && item.status === 'pending')?_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"red","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red","dark":"","icon":"","small":""},on:{"click":function($event){return _vm.decline(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("invites.reject"))+" ")]):_vm._e(),(!item.is_mine && item.status === 'accepted')?_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"red","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red","dark":"","icon":"","small":""},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-logout")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("invites.leave"))+" ")]):_vm._e(),(item.is_mine && item.resultFragmentAccess)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"red","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red","dark":"","icon":"","small":""},on:{"click":function($event){return _vm.removeCoworker(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-remove")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("invites.revokeAccess"))+" ")]):_vm._e(),(item.is_mine && !item.resultFragmentAccess)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"red","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red","dark":"","icon":"","small":""},on:{"click":function($event){return _vm.deleteDocument(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("invites.deleteDocument"))+" ")]):_vm._e()],1)])]}},{key:"item.doc_name",fn:function(ref){
var item = ref.item;
return [_c('v-container',[(item.status !== 'accepted' && !item.is_mine)?_c('div',[_vm._v(" "+_vm._s(item.doc_name)+" ")]):_c('div',[_c('router-link',{attrs:{"to":{ name: 'Project', params: { id: item.doc_id } }}},[_vm._v(_vm._s(item.doc_name))])],1),_c('br'),_c('v-spacer'),_c('TagsPlate',{attrs:{"color":"#ADD8E6","tags":item.tags}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-container',[(item.status)?_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":_vm.getStatusColor(item.status),"dense":"","small":"","light":"","text-color":_vm.getStatusTextColor(item.status)}},[_vm._v(" "+_vm._s(_vm.$t(("status." + (item.status))))+" ")]):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-container',[_vm._v(" "+_vm._s(_vm.$date(item.createdAt))+" ")])]}},{key:"item.lastModified",fn:function(ref){
var item = ref.item;
return [_c('v-container',[_vm._v(" "+_vm._s(item.lastModified ? _vm.$date(item.lastModified) : "")+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('v-container',[(item.is_mine)?_c('div'):_c('div',[_vm._v(" "+_vm._s(_vm.getDocumentOwner(item))+" ")])])]}},{key:"item.coworker",fn:function(ref){
var item = ref.item;
return [_c('v-container',[(!item.is_mine)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.getDocumentOwner(item)))]),_c('br'),_vm._v(" "+_vm._s(_vm.$print_role("owner").toLowerCase())+" ")]):_c('div',[_c('b',[_vm._v(_vm._s(_vm.getDocumentCoworker(item)))]),_c('br'),_vm._v(" "+_vm._s(_vm.getDocumentCoworkerRoles(item))+" ")])])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('v-container',[(!item.is_mine)?_c('div',[_vm._v(" "+_vm._s(_vm.getDocumentCoworkerRoles(item))+" ")]):_c('div',[_vm._v(_vm._s(_vm.$print_role("owner").toLowerCase()))])])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag){return _c('v-chip',{key:tag,staticClass:"ma-1 rounded-0",attrs:{"small":"","dense":"","label":"","color":"green"}},[_vm._v(" "+_vm._s(tag)+" ")])})}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }