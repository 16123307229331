<template>
  <v-container>
    <DashboardCollab
      :filter="['owner']"
      compact="owner"
      :title="$t('learningDesign.myLearningDesigns')"
      action-new-project
    />
  </v-container>
</template>

<script>
import DashboardCollab from "./DashboardCollab.vue";

export default {
  name: "Projects",
  components: {
    DashboardCollab,
  },
};
</script>
