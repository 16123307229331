function mapppingProject(result) {
  result.root = result?.root?.[0] || false;
  result._id = result.root.id;
  result.createdAt = result.root.createdAt;
  result.updatedAt = result.root.lastModified;
  result.creatorId = result.root.createdById;
  result.updatedById = result.root.createdById;

  result.data = {};
  result.data.title = result.root.data.name;
  result.data.config = {
    config: {
      preset: "blank",
      editor: "editorjs",
    },
  };
  result.extras = {
    title: result.data.title,
  };
  result.courseId = null;
  result.courseName = null;
  result.revisionCount = 0;

  result.id = result?.root?.id;

  result.data.lists = (result?.column || []).sort((a, b) => {
    if (a.data.index > b.data.index) return 1;
    if (a.data.index < b.data.index) return -1;
    return 0;
  });

  result.data.lists.forEach((list) => {
    list.cards = [];
    list.type = list?.extras?.type || false;
    list.fixed = list?.extras?.fixed || false;
  });

  const mapids = {};
  result.data.lists.forEach((list, i) => {
    mapids[list.id] = i;
  });

  result.data.objects = [];

  (result.card || []).forEach((card) => {
    let cid = mapids[card.parentFragmentId];
    let index = card.data.indexes[0];
    if (typeof cid !== "undefined") {
      card.type = card.extras.type;
      card.title = card.data.title;
      card.content = JSON.parse(card.data.body || JSON.stringify({}));
      result.data.objects.push(card);
      result.data.lists[cid].cards.push([card.id, index]);
    }
  });

  result.data.lists.forEach((list) => {
    let maxIndex = 0;
    list.cards.forEach((card) => {
      maxIndex = Math.max(card[1] || 0, maxIndex);
    });
    list.maxIndex = maxIndex;

    list.cards.sort((a, b) => {
      if (a[1] > b[1]) return 1;
      if (a[1] < b[1]) return -1;
      return 0;
    });
    list.cards = list.cards.map((card) => card[0]);
  });

  return result;
}

async function getProject(state, req) {
  let result = req;
  if (req?.doc) result = req.doc;

  result = mapppingProject(result, state);

  // console.log(result.users);

  state.data.project = result;
  const createdBy = result?.root?.createdById;

  state.data.project.myRole =
    createdBy === state.user.id ? "owner" : "coworker";

  let myRoles = [];
  if (createdBy === state.user.id) myRoles.push("owner");
  state.data.project.users.forEach((u) => {
    if (u.userId === state.user.id) {
      myRoles = [...myRoles, ...u.roles];
    }
    if (u.userId === createdBy) {
      u.roles.push("owner");
    }
  });
  state.data.project.myRoles = myRoles;

  if (myRoles.indexOf("editor") !== -1) {
    state.data.project.canEdit = true;
  } else {
    state.data.project.canEdit = false;
  }

  // console.log(state.data.project.users);
  /**
  if (state.data.project.myRole === "owner") {
    state.data.project.canEdit = true;
    state.data.project.users = state.data.project.users.filter(
      (u) => u.userId !== state.user.id
    );
  } else {
    state.data.project.canEdit = false;
  }
  state.data.project.canEdit = true; **/

  state.data.project.participants = [];
  // TODO: invites: выбор участника из списка студентов курса
  /**
  if (state?.data?.project?.courseId) {
    const course = await this.dispatch(
      "getCourse",
      state?.data?.project?.courseId
    );
    if (course && course.users) {
      state.data.project.participants = (course?.users || [])
        .filter((u) => u?.role === "teacher" || u?.role === "supervisor")
        .map((u) => {
          const label =
            (u.name !== u.email && u.name?.length > 0 ? u.name : u.email) +
            " [ " +
            u.role +
            " ] ";
          return {
            label,
            email: u.email,
            role: u.role,
          };
        });
    }
  } **/

  if (req?.callback) {
    req.callback();
  }
}

export default {
  getProject,
};
