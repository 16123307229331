import Vue from "vue";
import VueRouter from "vue-router";
import Courses from "../views/Courses.vue";
import Projects from "../views/Projects.vue";
import Home from "../views/Home.vue";
// import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/courses",
    component: Courses,
    name: "Courses",
  },
  {
    path: "/projects",
    component: Projects,
    name: "Projects",
  },
  {
    path: "/projects/:id",
    name: "Project",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "project" */ "../components/Project.vue"),
    children: [
      {
        path: "/project/:id/objects/:item",
        name: "Object",
        component: () =>
          import(
            /* webpackChunkName: "project-editor" */ "../components/Project/CardEditor.vue"
          ),
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ "../components/Admin.vue"),
  },
  {
    path: "/collab",
    name: "DashboardCollab",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "invites" */ "../components/DashboardCollab.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  Vue.prototype.$checkAuth({ to, from, next });
});

export default router;
