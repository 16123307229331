/* eslint-disable no-unused-vars */

import Vue from "vue";

async function createCard(
  { dispatch, state, commit },
  {
    projectId = false,
    parentId,
    type,
    title,
    index = 0,
    refId,
    body = {},
    tags = [],
    callback,
    fixed = false,
  }
) {
  const rootId = projectId || state.data.project.id;
  const url = `/fragments/${rootId}/cards`;

  try {
    const res = await Vue.prototype.$api.post(url, {
      parentId,
      extras: {
        type,
        refId,
        fixed,
      },
      card: {
        title,
        body: JSON.stringify({}),
        indexes: [index],
        tags: [],
        attachments: [],
      },
    });
    const card = res.data.fragments.card[0];
    if (!callback) {
      dispatch("getProject", rootId);
    } else {
      callback({ card_id: card.id, doc_id: rootId });
    }
  } catch (error) {
    error;
  }
}

async function updateColumn({ dispatch, state, commit }, { cid, data }) {
  const rootId = state.data.project.id;
  const url = `/fragments/${rootId}/columns/${cid}`;

  const res = await Vue.prototype.$api.patch(url, {
    parentId: rootId,
    column: {
      index: data.index,
    },
  });
  dispatch("getProject", rootId);
}

async function updateCardIndex(
  { dispatch, state, commit },
  { cid, parentId, data }
) {
  const rootId = state.data.project.id;
  const url = `/fragments/${rootId}/cards/${cid}`;

  const res = await Vue.prototype.$api.patch(url, {
    parentId,
    card: {
      indexes: data.index,
    },
  });
  dispatch("getProject", rootId);
}

async function updateCard(
  { dispatch, state, commit },
  { id, title, body, type, tags = [] }
) {
  const rootId = state.data.project.id;
  const url = `/fragments/${rootId}/cards/${id}`;

  const card = {
    tags,
  };
  if (title) card.title = title;
  if (body) card.body = body;
  const res = await Vue.prototype.$api.patch(url, {
    card,
  });
  if (type === "teaching-design") {
    await dispatch("renameProject", { id: rootId, title, tags });
  } else {
    await dispatch("getProject", rootId);
  }
  state.updatesCount++;
}

async function deleteCard({ dispatch, state, commit }, { id }) {
  const rootId = state.data.project.id;
  const url = `/fragments/${rootId}/cards/${id}/archive`;

  const res = await Vue.prototype.$api.patch(url);

  state.updatesCount++;
  dispatch("getProject", rootId);
}

export default {
  createCard,
  updateColumn,
  updateCardIndex,
  updateCard,
  deleteCard,
};
