/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
// import VuexPersistence from "vuex-persist";
import { v4 as uuidv4 } from "uuid";
import { diff } from "just-diff";

import actionsProject from "./projects/actions";
import mutationsProject from "./projects/mutations";

import actionsCard from "./cards/actions";

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const PRESETS_DEMO = require("./presets_demo.json");
const DESIGN_SCHEME = require("./presets_design_scheme.json");

// eslint-disable-next-line no-unused-vars
/**
const vuexLocal = new VuexPersistence({
  key: "iluks",
  storage: window.localStorage,
  reducer: (state) => {
    return { data: state.data };
  },
}); **/

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    aid: 0,
    appIsLoaded: false,
    isAuth: undefined,
    user: false,
    historyId: 0,
    updatesCount: 0,
    showComments: true,
    sortComments: false,
    data: {
      index: 0,
      project: {},
      projects: [],
      courses: [],
      sharedProjects: [],
      otherProjects: [],
      projectsCount: 0,
    },
  },
  mutations: {
    getProject: mutationsProject.getProject,
    async createProject(state, type) {},
    async updateProject(state, project) {},

    // TODO: update getCourses
    getCourses(state, result) {
      /**
      state.data.courses = [];
      (result?.data?.result || []).sort((a, b) => {
        if (a.updatedAt > b.updatedAt) return -1;
        if (a.updatedAt < b.updatedAt) return 1;
        return 0;
      });
      state.data.courses = [...result.data.result];
      **/
    },
    getProjects(state, result) {
      state.data.projects = [];
      state.data.sharedProjects = [];
      state.data.otherProjects = [];

      (result?.data?.result || []).sort((a, b) => {
        if (a.updatedAt > b.updatedAt) return -1;
        if (a.updatedAt < b.updatedAt) return 1;
        return 0;
      });
      (result?.data?.result || []).forEach((project) => {
        project.myRole = project?.users.filter(
          (u) => u?.id === state.user.id
        )?.[0]?.role;
        if (project.myRole === "owner") {
          state.data.projects.push(project);
        } else {
          project.owner = project.users.filter((u) => u?.role === "owner")?.[0];
          if (project.owner) {
            if (project.myRole) {
              state.data.sharedProjects.push(project);
            } else {
              state.data.otherProjects.push(project);
            }
          }
        }
      });
    },
  },

  actions: {
    async checkAuth({ state }) {
      // TODO: Ask user for name correction
      const res = await Vue.prototype.$ph(Vue.prototype.$api.get("/users/@me"));
      if (res[1] || !res[0]?.data?.result) {
        state.isAuth = false;
        state.user = false;
      } else {
        state.isAuth = true;
        state.user = res[0].data.result;
        if (!state.user.name) {
          state.user.name = (
            state.user.firstName +
            " " +
            state.user.lastName
          ).trim();
        }
      }
    },

    async getCourses({ state, commit }) {
      const params = {};
      if ((state.user.roles || []).indexOf("course_owner") !== -1)
        params.query_mode = "all";
      const result = await Vue.prototype.$api.get("/courses/", { params });
      commit("getCourses", result);
    },
    async deleteCourses({ state, commit }, id) {
      const params = {};
      if (state.user.roles.indexOf("course_owner") !== -1)
        params.query_mode = "all";
      const result = await Vue.prototype.$api.get("/courses/", { params });
      commit("getCourses", result);
    },

    createProject: actionsProject.createProject,
    getProject: actionsProject.getProject,
    getProjects: actionsProject.getProjects,
    updateProject: actionsProject.updateProject,
    deleteProject: actionsProject.deleteProject,
    renameProject: actionsProject.renameProject,

    createCard: actionsCard.createCard,
    updateColumn: actionsCard.updateColumn,
    updateCardIndex: actionsCard.updateCardIndex,
    updateCard: actionsCard.updateCard,
    deleteCard: actionsCard.deleteCard,

    async createCourse({ commit }, params) {
      const result = await Vue.prototype.$api.post("/courses/", {
        name: params.name,
        extras: params.extras || {},
      });
      return result.data;
    },
    async getCourse({ commit }, courseId) {
      const result = await Vue.prototype.$api.get("/courses/" + courseId);
      return result.data.result;
    },
    async addUsersToCourse({ commit }, params) {
      const result = await Vue.prototype.$api.patch(
        "/courses/" + params.courseId + "/users",
        {
          users: params.users,
        }
      );
    },
    async updateCourse({ commit }, params) {
      const result = await Vue.prototype.$api.patch(
        "/courses/" + params.courseId,
        {
          name: params.name,
          startDate: params.startDate || null,
          endDate: params.endDate || null,
          extras: params.extras || {},
        }
      );
    },
  },
  modules: {},
  getters: {
    isAuth: (state) => state.isAuth,
    user: (state) => state.user,
    projects: (state) => state.data.projects,
    courses: (state) => state.data.courses,
    sharedProjects: (state) => state.data.sharedProjects,
    otherProjects: (state) => state.data.otherProjects,
    project: (state) => state.data.project,
    DESIGN_SCHEME: () => DESIGN_SCHEME,
    appIsLoaded: (state) => state.appIsLoaded,
    showComments: (state) => state.showComments,
    sortComments: (state) => state.sortComments,
    updatesCount: (state) => state.updatesCount,
    isAdmin: (state) =>
      state?.isAuth &&
      (state?.user?.roles || []).indexOf("administrator") !== -1,
  },
  // plugins: [vuexLocal.plugin],
});
